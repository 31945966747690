import React, { createContext, useState, useEffect } from "react"
import products from "../Product Section/Products" 

export const CartContext = createContext()

export function CartProvider({ children }) {
  const [cartProduct, setCartProduct] = useState([])
  const [cartToggle, setCartToggle] = useState(false)
  const [showIcon, setShowIcon] = useState(true)
  const [showCloseButton, setShowCloseButton] = useState(false)

  function toggleCart() {
    setCartToggle((prevCart) => !prevCart)
    setShowCloseButton((prevButton) => !prevButton)
    setShowIcon((prevIcon) => !prevIcon)
  }

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []
    const initializedCart = storedCart.map(({ barcode, quantity }) => {
      const product = products.find(p => p.barcode === barcode)
      return product ? { ...product, quantity } : null
    }).filter(Boolean)
    
    setCartProduct(initializedCart)
  }, [])

  useEffect(() => {
    const cartToStore = cartProduct.map(({ barcode, quantity }) => ({ barcode, quantity }))
    localStorage.setItem('cart', JSON.stringify(cartToStore))
  }, [cartProduct])

  function addProductToCart(product) {
    setCartProduct((prevProducts) => {
      const existingProduct = prevProducts.find(item => item.barcode === product.barcode)

      if (existingProduct) {
        return prevProducts.map(item =>
          item.barcode === product.barcode ? { ...item, quantity: item.quantity + 1 } : item
        )
      } else {
        return [...prevProducts, { ...product, quantity: 1 }]
      }
    })
    if (!cartToggle && window.innerWidth > 768) {
      toggleCart()
    }
  }

  function incrementCartQuantity(product) {
    setCartProduct((prevProducts) =>
      prevProducts.map((item) =>
        item.barcode === product.barcode ? { ...item, quantity: item.quantity + 1 } : item
      )
    )
  }

  function decrementCartQuantity(product) {
    setCartProduct((prevProducts) =>
      prevProducts.map((item) =>
        item.barcode === product.barcode
          ? item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : null
          : item
      ).filter(Boolean)
    )
  }

  function removeProduct(product) {
    setCartProduct((prevProducts) =>
      prevProducts.filter((item) => item.barcode !== product.barcode) 
    )
  }

  function sumOfItemPrices() {
    return cartProduct.reduce((total, item) => total + item.price * item.quantity, 0)
  }

  function transformOrderDataToJSON() {
    return {
      products: cartProduct.map(item => ({
        Ürün: item.title,
        ÜrünFiyatı: item.price,
        ÜrünSayısı: item.quantity
      })),
      SepetToplamı: sumOfItemPrices()
    }
  }

  return (
    <CartContext.Provider
      value={{
        cartProduct,
        addProductToCart,
        incrementCartQuantity,
        decrementCartQuantity,
        sumOfItemPrices,
        removeProduct, 
        transformOrderDataToJSON,
        cartToggle,
        showIcon,
        showCloseButton,
        toggleCart
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

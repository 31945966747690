import React from "react"

export default function Modal({ isOpen, children }) {
  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
      </div>
    </div>
  )
}

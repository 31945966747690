import './App.css'
import React from 'react'
import Navbar from './sections/Navbar'
import ProductMenu from './sections/Product Section/ProductMenu'
import { CartProvider } from './sections/Cart Section/CartProvider'
import Location from './sections/RestaurantLocation'
import GetLocation from './sections/GetLocation'
import GoogleReviews from './sections/Review Section/GoogleReviews'
import Footer from './sections/Footer'

export default function App() {
  return (
    <div className="App">
        <Navbar />
            <CartProvider>
              <ProductMenu />
              <GetLocation />
            </CartProvider> 
          <GoogleReviews />
          <Location />
          <Footer />
    </div>
  )
}
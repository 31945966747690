import React, { useState, useEffect } from "react"
import { GoogleMap, useLoadScript, Circle, Marker } from "@react-google-maps/api"
import pilavSoleniLogo from "../images/pilav-soleni-logo-resized.png" 

const libraries = ["places"]
const mapContainerStyle = {
    width: "80vw",
    height: "80vh",
}

export default function DistanceMap({ onClose }) {
    const coords = { lat: 40.945333089189695, lng: 29.119637725783186 }
    const [isLoaded, setIsLoaded] = useState(false)
    const [loadError, setLoadError] = useState(null)
    const [zoomLevel, setZoomLevel] = useState(14.8)
    const [isPhoneSize, setIsPhoneSize] = useState(false)

    const { isLoaded: mapLoaded, loadError: mapLoadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCSi5DoaMDvFWn5wSrp9z3NdK-wCJ_vlok",
        libraries,
    })

    useEffect(() => {
        if (mapLoaded) {
            setIsLoaded(true)
        }
        if (mapLoadError) {
            setLoadError("Map loading failed.")
        }
    }, [mapLoaded, mapLoadError])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsPhoneSize(true)
                setZoomLevel(14)
            } else {
                setIsPhoneSize(false)
                setZoomLevel(14.8)
            }
        }

        handleResize()

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    if (loadError) {
        return <div>Harita Yüklenirken Bir Problem Oluştu</div>
    }

    if (!isLoaded) {
        return <div>Harita Yükleniyor ...</div>
    }

    return (
        <div className="map-only-modal">
            <div className="modal-close-container">
                <i
                    className="fa-solid fa-xmark modal-close"
                    onClick={onClose}
                    style={{
                        background: "none",
                        border: "none",
                        fontSize: "24px",
                        cursor: "pointer",
                        color: "red",
                        marginRight: isPhoneSize ? "1em" : "10px",
                        marginLeft: !isPhoneSize ? "auto" : undefined,
                    }}
                />
            </div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={coords}
                zoom={zoomLevel}
                options={{
                    gestureHandling: 'greedy',
                    draggable: false, 
                }}
            >
                <Circle
                    center={coords}
                    radius={1500}
                    options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.10,
                    }}
                />
                <Marker
                    position={coords}
                    icon={{
                        url: pilavSoleniLogo, 
                        scaledSize: new window.google.maps.Size(50, 50), 
                    }}
                />
            </GoogleMap>
        </div>
    )
}

import { useContext } from 'react'
import { CartContext } from './CartProvider'

export default function UserCartIcon({ toggleCart, showIcon }) {
    const { cartProduct } = useContext(CartContext)


    const totalQuantity = cartProduct.reduce((total, product) => total + product.quantity, 0)
    function handleClick() {
        toggleCart()
    }

    return (
        <div className="cart-icon-container">
            {totalQuantity > 0 && showIcon && (
                <div className="product-quantity-in-cart">
                   <p>{totalQuantity}</p> 
                </div>
            )}
            <i className="fa-solid fa-cart-shopping fa-2x"
                onClick={handleClick}
                style={{ background: 'none', border: 'none', padding: 5, cursor: 'pointer', userSelect: 'none' }}
                aria-label="Cart">
            </i>
        </div>
    )
}

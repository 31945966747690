import { useState, useEffect, useCallback } from 'react'
import cropLogo from '../images/pilav-soleni-logo-crop.png'

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false) 
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev)
  }

  const handleResize = useCallback(() => {
    const currentWidth = window.innerWidth
    setIsDesktop(currentWidth > 768)
    if (currentWidth > 768) {
      setIsMenuOpen(true) 
    } else if (!isMenuOpen) {
      setIsMenuOpen(false) 
    }
  }, [isMenuOpen])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize() 

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize]) 

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="logo">
          <img
            id='small-logo'
            className="main-logo"
            src={cropLogo}
            alt="Logo"
            style={{ width: '100px', height: 'auto' }}
          />
        </a>
        <div className='navbar-pc'>
          <ul className='navbar-pc-container'>
            <li className='nav-item'>
              <a href="/" className="logo">
                <img
                  className="main-logo"
                  src={cropLogo}
                  alt="Logo"
                  style={{ width: '100px', height: 'auto' }}
                />
              </a>
            </li>
            <li className="nav-item">
              <a href="#Menu-title" className="nav-link">Menü</a>
            </li>
            <li className="nav-item">
              <a href="#google-comments" className="nav-link">Yorumlarımız</a>
            </li>
            <li className="nav-item">
              <a href="#map-section" className="nav-link">Konumumuz</a>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
        </div>
        {(isDesktop || isMenuOpen) && ( 
          <ul className={`nav-menu ${isDesktop ? 'inactive' : 'active'}`}>
            <li className="nav-item">
              <a href="#Menu-title" className="nav-link">Menü</a>
            </li>
            <li className="nav-item">
              <a href="#google-comments" className="nav-link">Yorumlarımız</a>
            </li>
            <li className="nav-item">
              <a href="#map-section" className="nav-link">Konumumuz</a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  )
}

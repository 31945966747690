import React, { useState, useContext, useEffect } from "react"
import { CartContext } from "./CartProvider"
import googleLocationIcon from '../../images/icons/google-maps-logo-address-23486.png'
import deliverIcon from '../../images/icons/⁬deliverIcon.png'
import Modal from "../Modal"
import CombinedModal from "../CombinedModal"
import DistanceMap from "../distanceMap"

export default function InsideCart() {
    const [isModalOpen, setModalOpen] = useState(false)
    const [isDistanceMapOpen, setDistanceMapOpen] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [userLocation, setUserLocation] = useState({ lat: null, lng: null })
    const [isDeliverable, setIsDeliverable] = useState(false)

    const { cartProduct, incrementCartQuantity, decrementCartQuantity, sumOfItemPrices, removeProduct, transformOrderDataToJSON } = useContext(CartContext)
    const minCartLimit = 120
    const freeDeliveryLimit = 250
    const delivery_fee = 20

    const totalAmount = sumOfItemPrices()
    const deliveryFee = totalAmount < freeDeliveryLimit ? delivery_fee : 0
    const finalTotal = totalAmount + deliveryFee

    const restaurantLocation = { lat: 40.945333089189695, lng: 29.119637725783186 }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedLocation = JSON.parse(localStorage.getItem('userLocation'))
            if (savedLocation) {
                setUserLocation(savedLocation)
            }
        }, 0)
        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        if (userLocation.lat && userLocation.lng) {
            const distance = calculateDistance(userLocation.lat, userLocation.lng, restaurantLocation.lat, restaurantLocation.lng)
            setIsDeliverable(distance <= 1.5)
        }
    }, [userLocation])

    useEffect(() => {
        localStorage.setItem('totalPrice', finalTotal)
    }, [finalTotal])

    function calculateDistance(lat1, lng1, lat2, lng2) {
        const R = 6371
        const dLat = (lat2 - lat1) * (Math.PI / 180)
        const dLon = (lng2 - lng1) * (Math.PI / 180)
        const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) ** 2
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        return R * c
    }

    function handleOpenModal() {
        setModalOpen(true)
    }

    function handleCloseModal() {
        setModalOpen(false)
    }

    function handleWarning() {
        if (totalAmount < minCartLimit) {
            setShowWarning(true)
        } else {
            setShowWarning(false)
            handleOrder()
        }
    }

    function handleOrder() {
        const data = transformOrderDataToJSON()
        const userAddressFromLocal = localStorage.getItem('userAddress') || ""
        const floor = localStorage.getItem('floor') || ""
        const apartmentNo = localStorage.getItem('apartmentNo') || ""
        const buildingNo = localStorage.getItem('buildingNo') || ""
        const addressNote = localStorage.getItem('addressNote') || "" 
        const totalCartPrice = localStorage.getItem('totalPrice') || ""

        let message = `Merhaba, sipariş vermek istiyorum.\nAdresim: ${userAddressFromLocal}\n`

        if (floor && floor.trim() !== "") {
            message += `Kat No: ${floor}\n`
        }

        if (apartmentNo && apartmentNo.trim() !== "") {
            message += `Daire No: ${apartmentNo}\n`
        }

        if (buildingNo && buildingNo.trim() !== "") {
            message += `Bina No: ${buildingNo}\n`
        }

        if (addressNote && addressNote.trim() !== "") {
            message += `Adres Tarifi: ${addressNote}\n` 
        }

        message += `\nSiparişim:\n`

        data.products.forEach(function (product) {
            message += `- ${product.ÜrünSayısı}x ${product.Ürün}\n`
        })

        message += `\nToplam Ücret: ${totalCartPrice}₺`

        const phoneNumber = "905378679724"
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
        window.open(whatsappURL, '_blank')
    }

    const remainingForFreeDelivery = freeDeliveryLimit - totalAmount

    function openDistanceMap() {
        setDistanceMapOpen(true)
    }

    function closeDistanceMap() {
        setDistanceMapOpen(false)
    }

    return (
        <div className="inside-cart">
            {cartProduct.length === 0 ? (
                <div className="empty-cart">
                    <h2>Sepetiniz Boş</h2>
                    <p>Lütfen Ürün Ekleyin</p>
                </div>
            ) : (
                <>
                    <div className="product-section-inside-cart">
                        {cartProduct.map((product, index) => (
                            <div key={index} className="product-inside-cart">
                                <div className="product-top">
                                    <img className="product-inside-cart-image" src={product.imageUrl} alt={product.title} />
                                    <h3 className="product-inside-cart-title">{product.title}</h3>
                                    <p className="product-inside-cart-price">
                                        {(product.price * product.quantity).toLocaleString('tr-TR', { minimumFractionDigits: 0 })}<span className="decimal">, 00</span> ₺
                                    </p>
                                </div>
                                <div className="product-buttons">
                                    <div className="quantity-buttons-wrapper">
                                        <button className="quantity-button" onClick={() => decrementCartQuantity(product)}><i className="fa-solid fa-minus"></i></button>
                                        <div className="quantity-numbers">{product.quantity}</div>
                                        <button className="quantity-button" onClick={() => incrementCartQuantity(product)}><i className="fa-solid fa-plus"></i></button>
                                    </div>
                                    <button className="remove-from-cart-button" onClick={() => removeProduct(product)}>
                                        <i className="fa-solid fa-trash-can fa-xl"></i>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="cart-bottom">
                        <div className={`delivery-message ${remainingForFreeDelivery <= 0 || !localStorage.getItem('userAddress') ? 'hidden' : ''}`}>
                            {remainingForFreeDelivery > 0 && 
                                `Ücretsiz Teslimat İçin Kalan Ücret: ${remainingForFreeDelivery.toLocaleString('tr-TR', { minimumFractionDigits: 0 })}₺`}
                        </div>
                        <div className="cart-price-wrapper">
                            <div className="order-deliver-line">
                                <img className="deliver-icon" src={deliverIcon} alt="Deliver" />
                                <h3>Teslimat: 
                                    <span className={deliveryFee > 0 ? "deliver-span delivery-fee" : "deliver-span"}>
                                        {deliveryFee > 0 ? `${deliveryFee}₺` : " ÜCRETSİZ"}
                                    </span>
                                </h3>
                            </div>
                            <h3 className="sum-of-cart">
                                Sepet Toplamı: <span id="cartTotal">{finalTotal.toLocaleString('tr-TR', { minimumFractionDigits: 0 })}<span className="decimal">,00</span></span> ₺
                            </h3>
                        </div>
                        {isDeliverable ? (
                            <>
                                {!userLocation.lat || !userLocation.lng ? (
                                    <div className="location-off-error-message">
                                        <img className="google-location-icon" src={googleLocationIcon} alt="Location" />
                                        Lütfen Konum Bilginizi Açınız!
                                    </div>
                                ) : (
                                    <>
                                        {showWarning && (
                                            <div className="warning-message">
                                                <p className="min-cart-price">Minimum Sepet Tutarımız <span className="bold">120<span className="decimal">,00</span></span> ₺'dir.</p>
                                            </div>
                                        )}
                                        <button className="first-order-button" onClick={handleWarning}>
                                            <i className="fa-brands fa-whatsapp fa-xl"></i>
                                            <p>Sipariş Ver</p>
                                        </button>
                                    </>
                                )}
                            </>
                        ) : !localStorage.getItem('userAddress') ? (
                            <div className="out-of-range-error-container">
                                Lütfen Adres Bilgilerinizi Doldurun.
                            </div>
                        ) : (
                            <div className="out-of-range-error-container">
                                <span className="sadly-we-cant-take-your-order">
                                    Maalesef adresinize teslimat yapamıyoruz 😔
                                </span>
                                <span className="order-radious-text" onClick={openDistanceMap}><i className="fa-solid fa-map-pin"></i>Buraya tıklayarak teslimat yapabildiğimiz bölgeyi görüntüleyebilirsiniz</span>
                            </div>
                        )}
                        <div onClick={handleOpenModal} className="address-section">
                            <p className="user-address-inf">
                                {localStorage.getItem('userAddress') || "Adres Bilgilerinizi Doldurmak İçin Tıklayın"}
                            </p>
                            <p className="checking-location-button" onClick={handleOpenModal}>
                                <i className="fa-solid fa-location-crosshairs fa-lg"></i>
                            </p>
                        </div>
                    </div>
                </>
            )}
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <CombinedModal onClose={handleCloseModal} />
            </Modal>
            <Modal isOpen={isDistanceMapOpen} onClose={closeDistanceMap}>
                <DistanceMap onClose={closeDistanceMap} />
            </Modal>
        </div>
    )
}

import React, { useState, useEffect, useCallback } from "react"
import Cart from "./Cart Section/Cart"

export default function GetLocation() {
  const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null })
  const [isDeliverable, setIsDeliverable] = useState(false)
  const [userLocationOn, setUserLocationOn] = useState(false)
  const [userAddress, setUserAddress] = useState(null)

  const restaurantLocation = { latitude: 40.945333089189695, longitude: 29.119637725783186 }
  const googleMapsApiKey = "AIzaSyCSi5DoaMDvFWn5wSrp9z3NdK-wCJ_vlok"

  const getLocation = useCallback(async () => {
    const savedLocation = JSON.parse(localStorage.getItem('userLocation'))

    if (savedLocation) {
      const location = { latitude: savedLocation.lat, longitude: savedLocation.lng }
      setUserLocation(location)
      setUserLocationOn(true)

      getAddressFromCoordinates(location)
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          const location = { latitude, longitude }
          setUserLocation(location)
          setUserLocationOn(true)
          getAddressFromCoordinates(location)
          localStorage.setItem('userLocation', JSON.stringify({ lat: latitude, lng: longitude }))
        },
        handleError
      )
    }
  }, [])

  const getAddressFromCoordinates = async ({ latitude, longitude }) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapsApiKey}`)
      const data = await response.json()
      if (data.results && data.results.length > 0) {
        const address = data.results[0].formatted_address
        setUserAddress(address)
      } else {
        console.error('No address found')
      }
    } catch (error) {
      console.error('Error fetching address:', error)
    }
  }

  const handleError = (error) => {
    console.error("Error fetching location:", error)
    setIsDeliverable(false)
    setUserLocationOn(false)
  }

  useEffect(() => {
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then(permissionStatus => {
        if (permissionStatus.state === "granted") {
          getLocation()
        } else {
          setIsDeliverable(false)
          setUserLocationOn(false)
        }

        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            getLocation()
          } else {
            setIsDeliverable(false)
            setUserLocationOn(false)
          }
        }
      })
    } else {
      getLocation()
    }
  }, [getLocation])

  useEffect(() => {
    const locationCheckInterval = setInterval(() => {
      const savedAddress = localStorage.getItem('userAddress')
      if (savedAddress) {
        setUserAddress(savedAddress)
      }
    }, 1000)

    return () => clearInterval(locationCheckInterval)
  }, [])

  useEffect(() => {
    if (userLocation.latitude && userLocation.longitude) {
      const distance = calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        restaurantLocation.latitude,
        restaurantLocation.longitude
      )
      setIsDeliverable(distance <= 1.5)
    }
  }, [userLocation])

  const calculateDistance = (userLatitude, userLongitude, restaurantLatitude, restaurantLongitude) => {
    const R = 6371
    const dLat = (restaurantLatitude - userLatitude) * (Math.PI / 180)
    const dLon = (restaurantLongitude - userLongitude) * (Math.PI / 180)
    const a = Math.sin(dLat / 2) ** 2 +
              Math.cos(userLatitude * (Math.PI / 180)) * Math.cos(restaurantLatitude * (Math.PI / 180)) *
              Math.sin(dLon / 2) ** 2
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = R * c

    return distance
  }

  return (
    <Cart 
      isDeliverable={isDeliverable} 
      userLocationOn={userLocationOn} 
      userAddress={userAddress} 
    />
  )
}

import { useContext, useState } from 'react'
import { CartContext } from '../Cart Section/CartProvider'
import Products from './Products.js'
import Modal from '../Modal.jsx'
import CombinedModal from '../CombinedModal.jsx'

export default function Product() {
  const { addProductToCart } = useContext(CartContext)
  const [loadingStates, setLoadingStates] = useState(Array(Products.length).fill(true))
  const [isAddressModalOpen, setAddressModalOpen] = useState(false)
  const [isPermissionModalOpen, setPermissionModalOpen] = useState(false)
  const [locationShared, setLocationShared] = useState(false)
  const [isCombinedModalOpen, setCombinedModalOpen] = useState(false)  
  const [tempProduct, setTempProduct] = useState(null) 

  function handleImageLoad(index) {
    setLoadingStates((prev) => {
      const newStates = [...prev]
      newStates[index] = false
      return newStates
    })
  }

  function handleAddressModalClose() {
    setAddressModalOpen(false)
    setPermissionModalOpen(false)
    setCombinedModalOpen(false) 
  }

  function handleAddToCart(product) {
    const locationData = localStorage.getItem('userLocation')

    if (locationData || locationShared) {
      addProductToCart(product)
    } else {
      setTempProduct(product)  
      setPermissionModalOpen(true) 
    }
  }

  function handleShareLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
          localStorage.setItem('userLocation', JSON.stringify(location))
          setLocationShared(true)
          setPermissionModalOpen(false)  
          setCombinedModalOpen(true)    
          
          if (tempProduct) {
            addProductToCart(tempProduct)
          }
        },
        (error) => {
          console.error('Geolocation error:', error)
          setPermissionModalOpen(false) 
        }
      )
    }
  }

  return (
    <>
      <h1 id="Menu-title">Menü</h1>
      <div className="menu-container menu">
        {Products.map((product, index) => (
          <div className='menu-item' key={index}>
            {loadingStates[index] && (
              <div className="loading-bar">
                <div className="loading" />
              </div>
            )}
            <img
              src={product.imageUrl}
              alt={product.title}
              className={`menu-item-image ${loadingStates[index] ? 'hidden' : ''}`}
              onLoad={() => handleImageLoad(index)}
              onError={() => handleImageLoad(index)} 
            />
            <div className='product-bottom-wrapper'>
              <h2 className="menu-item-title">{product.title}</h2>
              <p className="menu-item-description">{product.description}</p>
              <div className='cart-price-and-button'>
                <span className="menu-item-price">
                  <span className="fwb">{product.price}</span>
                  <span className="decimal">.00</span> ₺
                </span>
                <button onClick={() => handleAddToCart(product)} className="add-to-cart-button">
                  <i className="fa-solid fa-cart-plus"></i> Sepete Ekle
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isAddressModalOpen && (
        <Modal isOpen={isAddressModalOpen} onClose={handleAddressModalClose}>
          <CombinedModal onClose={handleAddressModalClose} />
        </Modal>
      )}
      {isPermissionModalOpen && (
        <Modal isOpen={isPermissionModalOpen} onClose={handleAddressModalClose}>
          <div className="warning-modal-content">
            <h2 className="warning-modal-title">Uyarı</h2>
            <p className="warning-modal-message">
              Siparişinizi Sizlere En Doğru Şekilde Ulaştırabilmemiz İçin Lütfen Konum Bilginizi Açınız
            </p>
            <div className='warning-modal-button-wrapper'>
              <button onClick={handleShareLocation} className="approve-modal-button">
                Lokasyon Bilgilerimi Paylaş
              </button>
              <button onClick={handleAddressModalClose} className="warning-modal-button">
                İptal
              </button>
            </div>
          </div>
        </Modal>
      )}
      {isCombinedModalOpen && (
        <Modal isOpen={isCombinedModalOpen} onClose={handleAddressModalClose}>
          <CombinedModal onClose={handleAddressModalClose} />
        </Modal>
      )}
    </>
  )
}

import trendyol from '../images/trendyol.png'
import yemekSepeti from '../images/yemeksepeti.png'

export default function Footer() {
    return (
        <div id="information" className="footer-wrapper">
            <h2 className="iletisim">İletişim</h2>
            <p><i className="fa-solid fa-phone"></i> +90 (537) 867 97 24 </p>
            <p className="mail"><i className="fa-solid fa-envelope"></i> siparis@pilavsoleni.com</p>
            <div>
                <a href="https://trendyolgo.com/" target="_">
                    <img 
                        className='trendyol-icon' 
                        src={trendyol} 
                        alt="Trendyol Hesabımıza Göz Atmanızı Yapmanızı Sağlayan Bir Görsel" 
                    />
                </a>
                <a href="https://www.yemeksepeti.com/restaurant/ei4u/pilav-soleni-ei4u" target="_">
                    <img 
                        className='yemeksepeti-icon' 
                        src={yemekSepeti} 
                        alt="YemekSepeti Hesabımıza Göz Atmanızı Yapmanızı Sağlayan Bir Görsel" 
                    />
                </a>
            </div>
            <p className="copyright">Copyright © 2024 Pilav Şöleni</p>
            <div className='creator-name'>
                Created by Phantmare (K.T.)
            </div>
        </div>
    )
}

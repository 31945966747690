export default function Location() {
    return (
        <div>
            <div className="map-section" id="map-section">
                <h1 id="location-title">Konumumuz</h1>
                <div className="gmap-frame">
                    <iframe 
                        id="gmap"
                        title="Google Maps - Pilav Şöleni Konumu" 
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Pilav%20%C5%9E%C3%B6leni+(Pilav%20SoleniLocation)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        <a href="https://www.gps.ie/">gps vehicle tracker</a>
                    </iframe>
                </div>
            </div>
        </div>
    )
}
